@sm: ~'(min-width: 576px)';
@md: ~'(min-width: 768px)';
@lg: ~'(min-width: 992px)';
@xl: ~'(min-width: 1200px)';

body {
    font-family: -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

a:hover {
    text-decoration: none;
}

.wrapper {
    position: relative;
}

.main {
    position: relative;
}

.header {
    height: 56px;
    z-index: 1024;
    position: relative;

    &.variant {
        @media (min-width: 576px) {
            position: relative;
        }
    }

    @media (min-width: 576px) {
        height: 80px;
        background: #fff;
    }

    &__inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 576px) {
            width: 850px;
            margin: 0 auto;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        margin-left: 26px;

        @media (min-width: 576px) {
            margin-left: 0;
        }

        img {
            width: 66px;

            @media (min-width: 576px) {
                width: 120px;
            }
        }
    }

    &__btn {
        width: 46px;
        padding: 0;
        border: 0;
        outline: none;
        background-color: transparent;
        font-size: 20px;
        cursor: pointer;
        color: #707070;

        &:focus {
            outline: none;
        }

        @media (min-width: 576px) {
            display: none;
        }
    }

    &__nav {
        display: none;

        @media (min-width: 576px) {
            display: flex;
            height: 100%;
        }

        a {
            @media (min-width: 576px) {
                color: rgba(0, 0, 0, 0.5);
                font-size: 14px;
                padding: 0;
                width: 64px + 36px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                transition: all 0.3s ease;
            }

            &:hover,
            &:active,
            &.active {
                color: #4a4a4a;
            }

            &.header__buy {
                @media (min-width: 576px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #c00;
                    width: 88px;
                    height: 34px;
                    line-height: 34px;
                    border-radius: 17px;
                    color: #fff;
                    margin-left: 16px;
                }
            }
        }
    }

    &__links {
        @media (min-width: 576px) {
            display: flex;
            align-items: center;
        }
    }

    &__small-nav {
        border-top: 1px solid rgba(153, 153, 153, 1);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        -webkit-transition: height 0.35s ease-out;
        transition: height 0.35s ease-out;
        display: none;
        overflow: hidden;

        &.show {
            display: block;
        }

        @media (min-width: 576px) {
            display: none;
        }
    }

    &__small-links {
        display: flex;
        flex-direction: column;
        padding: 40px 0;
    }

    &__small-link {
        position: relative;
        color: #505050;
        padding: 20px 40px;
        font-size: 12px;
        line-height: 20px;

        &.active {
            color: #000;

            &::before {
                background: #cc0000;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 27px;
            left: 30px;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: #707070;
        }

        &.products {
            padding-bottom: 10px;
        }
    }

    &__products {
        padding-top: 14px;
    }

    &__product {
        display: flex;
        align-items: center;

        img {
            width: 20px;
            margin-right: 6px;
        }

        span {
            color: #505050;
            font-size: 10px;
        }

        &:not(:first-child) {
            img {
                margin-top: -10px;
            }
        }

        &:last-child {
            img {
                width: 34px;
                margin-top: 0;
                margin-left: -14px;
                position: relative;
                left: 3px;
            }
        }
    }
}

.second-nav {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background: #fff;
    overflow: hidden;
    height: 0;

    &.show {
        transition: height 0.35s ease-out;

        .second-nav__link {
            animation-duration: 0.8s;
            animation-fill-mode: both;
            animation-name: fadeInRight;
        }
    }

    &__link {
        width: 200px;
        height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        > img {
            display: block;
            width: 100px;
        }

        > span {
            margin: 12px 0 54px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            transition: all 0.3s ease;
        }

        &:hover {
            > span {
                color: #000;
            }
        }

        &:last-child {
            animation-delay: 0.1s;

            // > img {
            //     width: 140px;
            //     margin-bottom: 16px;
            // }
        }
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.banner {
    @media (min-width: 576px) {
        height: 100vh;
        max-height: 1080px;
    }

    &__item {
        .lg {
            display: none;

            @media (min-width: 576px) {
                display: block;
                object-position: top;
            }
        }

        .sm {
            @media (min-width: 576px) {
                display: none;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        border: 1px solid #707070;
        background: transparent;
        opacity: 1;

        @media (min-width: 576px) {
            width: 15px;
            height: 15px;
        }

        &-active {
            background: #fff;
        }
    }

    & > .swiper-pagination-bullets {
        bottom: 5px;

        @media (min-width: 576px) {
            bottom: 25px;
        }

        .swiper-pagination-bullet {
            margin: 0 2px;

            @media (min-width: 576px) {
                margin: 0 5px;
            }
        }
    }

    &__buttons-wrapper {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
    }

    &__buttons {
        position: absolute;
        left: 16px;
        right: 0;
        bottom: 30px;
        z-index: 10;
        display: flex;

        @media (min-width: 576px) {
            left: 140px;
            bottom: 90px;
        }
    }

    &-button {
        display: flex;
        width: 66px;
        height: 22px;

        @media (min-width: 576px) {
            width: 120px;
            height: 40px;
        }

        & + & {
            margin-left: 10px;

            @media (min-width: 576px) {
                margin-left: 30px;
            }
        }

        > img {
            display: block;
            width: 100%;
        }
    }
}

.product-banner {
    @media (min-width: 576px) {
        height: 100vh;
        max-height: 1080px;
    }

    &__item {
        height: 100%;

        .lg {
            display: none;

            @media (min-width: 576px) {
                display: block;
            }
        }

        .sm {
            @media (min-width: 576px) {
                display: none;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.intro {
    max-width: 1920px;
    margin: 0 auto;
    padding-top: 18px;

    @media (min-width: 576px) {
        padding: 40px 0 160px;
    }

    &.product {
        .intro-item {
            // &:last-child {
            //     .intro-item__img {
            //         margin-bottom: 0;
            //     }
            // }

            @media (min-width: 576px) {
                flex-direction: row-reverse;

                &:nth-of-type(2n) {
                    flex-direction: row;
                }

                // &:last-child {
                //     justify-content: center;
                //     margin: 0;
                //     margin-top: 205px;

                //     .intro-item__img {
                //         flex: none;
                //         width: 310px;
                //         margin-right: 60px;
                //     }

                //     .intro-item__content {
                //         flex: none;
                //         width: 848px;
                //     }

                //     &.p1 {
                //         .intro-item__img {
                //             width: 470px;
                //         }
                //     }
                // }
            }
        }
    }

    &__item {
        &.a1,
        &.p1,
        &.x,
        &.b1 {
            flex-direction: row;
            justify-content: center;
            margin-bottom: 33px;

            @media (min-width: 576px) {
                margin-bottom: 172px;

                .intro-item + & {
                    margin-top: 146px;
                }
            }

            .intro-item__img {
                flex: none;
                width: 70px;
                margin-right: 16px;
                margin-bottom: 0;

                @media (min-width: 576px) {
                    width: 326px;
                    margin-right: 128px;
                    margin-bottom: 15px;
                }
            }

            .intro-item__content {
                flex: none;
                padding: 0;
            }
        }

        &.p1 {
            .intro-item__img {
                margin-right: 6px;
                width: 82px;

                @media (min-width: 576px) {
                    margin-right: 52px;
                    width: 480px;
                }
            }
        }

        &.x {
            @media (min-width: 576px) {
                .intro-item + & {
                    flex-direction: row;
                }
            }

            .intro-item__img {
                margin-right: 0;
                width: 110px;
                margin-right: -11px;

                @media (min-width: 576px) {
                    margin-right: 0;
                    width: 494px;
                }
            }
        }

        &.b1 {
            @media (min-width: 576px) {
                .intro-item + & {
                    flex-direction: row;
                }
            }

            .intro-item__img {
                margin-right: 6px;
                width: 84px;

                @media (min-width: 576px) {
                    width: 361px;
                    margin-right: 100px;
                }
            }
        }

        @media (min-width: 576px) {
            & + & {
                margin-top: 40px;
            }

            &:nth-of-type(2n) {
                flex-direction: row-reverse;
            }

            // &.a1 {
            //     .intro-item__img {
            //         width: 328px;
            //         margin-right: 106px;

            //         img {
            //             width: 100%;
            //         }
            //     }
            // }
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;

        @media (min-width: 576px) {
            flex-direction: row;
        }

        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (min-width: 576px) {
                padding: 0;
            }

            &--with-img {
                flex-direction: column-reverse;

                @media (min-width: 576px) {
                    flex-direction: column;
                }
            }
        }

        &__img {
            position: relative;
            flex: 1;
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 576px) {
                margin-bottom: 0;
            }

            img {
                width: 100%;
                height: 100%;
                vertical-align: bottom;
                object-fit: contain;
            }
        }

        &__name {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            color: #302d2c;

            @media (min-width: 576px) {
                display: none;
            }
        }

        &__head {
            color: #302d2c;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 12px;
            padding: 0 10px;

            @media (min-width: 576px) {
                font-weight: bold;
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 32px;
                padding: 0 40px;

                .X-steps & {
                    margin-bottom: 4px;
                }
            }

            @media (min-width: 1920px) {
                font-weight: bold;
                font-size: 32px;
                line-height: 36px;
                margin-bottom: 40px;
                padding: 0 60px;
            }
        }

        &__desc {
            font-size: 9px;
            line-height: 16px;
            color: #5f5e5e;
            padding: 0 10px;
            margin-bottom: 0;

            @media (min-width: 576px) {
                font-size: 18px;
                line-height: 1.5;
                padding: 0 40px;
            }

            @media (min-width: 1920px) {
                font-size: 20px;
                line-height: 34px;
                padding: 0 60px;
            }
        }

        &__certification-mark {
            display: none;
            width: 100%;
            vertical-align: bottom;

            @media (min-width: 576px) {
                margin-top: 42px;
                display: block;
            }

            &--mini {
                display: block;
                margin-top: 10px;

                @media (min-width: 576px) {
                    display: none;
                }
            }
        }

        &__steps {
            padding-left: 16px;
            padding-top: 20px;

            @media (min-width: 576px) {
                padding-top: 40px;
                padding-left: 40px;
            }

            @media (min-width: 1920px) {
                padding-top: 55px;
                padding-left: 60px;
            }
        }

        &__step {
            display: flex;

            & + & {
                margin-top: 24px;

                @media (min-width: 576px) {
                    margin-top: 26px;
                }

                @media (min-width: 1920px) {
                    margin-top: 34px;
                }
            }

            &-number {
                width: 28px;
                height: 28px;
                margin-right: 8px;
                background: #c00;
                border-radius: 50%;
                color: #fff;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (min-width: 576px) {
                    width: 48px;
                    height: 48px;
                    font-size: 28px;
                    line-height: 34px;
                    margin-right: 29px;
                }

                @media (min-width: 1920px) {
                    width: 56px;
                    height: 56px;
                    font-size: 28px;
                    line-height: 34px;
                    margin-right: 29px;
                }
            }

            &-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-name {
                color: #1a1311;
                font-size: 12px;
                line-height: 1;
                font-weight: bold;

                @media (min-width: 576px) {
                    font-size: 22px;
                }

                @media (min-width: 1920px) {
                    font-size: 25px;
                }

                .X-steps & {
                    color: #5f5e5e;
                }
            }

            &-desc {
                margin-top: 6px;
                font-size: 10px;
                line-height: 1;
                color: #5f5e5e;

                @media (min-width: 576px) {
                    font-size: 18px;
                    margin-top: 8px;
                }

                @media (min-width: 1920px) {
                    font-size: 20px;
                    margin-top: 13px;
                }
            }
        }

        &__table-name {
            font-size: 14px;
            line-height: 1;
            margin-bottom: 16px;
            font-weight: bold;
            color: #302d2c;

            @media (min-width: 576px) {
                margin-bottom: 40px;
                font-size: 32px;
                line-height: 44px;
            }
        }

        &__table-img {
            img {
                display: block;
                width: 100%;
            }

            &.lg {
                display: none;

                @media (min-width: 576px) {
                    display: block;
                }
            }

            &.sm {
                width: 180px;

                @media (min-width: 576px) {
                    display: none;
                }
            }
        }

        &__table {
            margin: 0 15px 20px;
            border: 1px solid #5f5e5e;
            border-left: none;
            border-right: none;

            @media (min-width: 576px) {
                margin: 0;
            }
        }

        &__tr {
            display: flex;
            border-bottom: 1px solid rgba(142, 142, 143, 1);

            &:last-child {
                border-bottom: none;
            }
        }

        &__td {
            padding: 0 8px;
            margin: 6px 0;
            width: 100% / 3;
            font-size: 9px;
            line-height: 16px;
            color: #5f5e5e;
            border-right: 1px solid #8e8e8f;

            &:last-child {
                border-right: none;
            }

            @media (min-width: 576px) {
                font-size: 20px;
                line-height: 38px;
            }
        }
    }
}

.footer {
    background: #eeeeee;
    padding: 14px 0;
    display: flex;
    justify-content: center;

    @media (min-width: 576px) {
        padding: 58px 0;
    }

    &__left,
    &__right {
        padding: 0 6px;

        @media (min-width: 576px) {
            padding: 0 50px;
        }
    }

    &__right {
        border-left: 1px solid #979797;
    }

    &-contact {
        &__title {
            color: #4a4a4a;
            font-size: 10px;
            font-weight: bold;
            line-height: 17px;
            margin-top: 4px;
            margin-bottom: 6px;

            @media (min-width: 576px) {
                font-size: 14px;
                line-height: 24px;
                margin-top: 14px;
                margin-bottom: 9px;
            }
        }

        &__content {
            font-size: 6px;
            color: #4a4a4a;

            @media (min-width: 576px) {
                font-size: 10px;
            }
        }

        &__meta {
            margin-bottom: 0;

            & + & {
                margin-top: 1px;

                @media (min-width: 576px) {
                    margin-top: 9px;
                }
            }

            a {
                color: #4a4a4a;

                &:hover {
                    color: lighten(#4a4a4a, 20%);
                }

                &:active {
                    color: darken(#4a4a4a, 5%);
                }
            }
        }

        &__sns {
            margin-top: 9px;
            font-size: 12px;
            display: flex;
            justify-content: space-between;

            @media (min-width: 576px) {
                margin-top: 34px;
                font-size: 27px;

                a + a {
                    margin-left: 30px;
                }
            }

            a {
                color: #000;

                &:hover {
                    color: lighten(#000, 20%);
                }

                &:active {
                    color: darken(#000, 5%);
                }
            }
        }

        &__company {
            margin-top: 12px;

            @media (min-width: 576px) {
                margin-top: 23px;
            }
        }
    }
}

.support {
    max-width: 1920px;
    margin: 0 auto;

    &-intro {
        display: flex;
        flex-direction: column;

        & + & {
            margin-top: 10px;

            @media (min-width: 576px) {
                margin-top: 40px;
            }
        }

        @media (min-width: 576px) {
            flex-direction: column;
        }

        &.application {
            @media (min-width: 576px) {
                .support-intro {
                    &__content {
                        flex: 1;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        &.more {
            margin-top: 0;
            margin-bottom: 138px;
        }

        &__title {
            margin-top: 30px;
            margin-bottom: 6px;
            font-size: 15px;
            line-height: 1;
            color: #333;
            padding: 0 9px;
            font-weight: bold;
            text-transform: uppercase;

            > span {
                color: #707070;
                font-weight: normal;
                text-transform: lowercase;
            }

            @media (min-width: 576px) {
                margin-top: 80px;
                margin-bottom: 65px;
                font-size: 36px;
                padding: 0 55px;
            }
        }

        &__video,
        &__download {
            flex: 1;
        }

        &__video {
            position: relative;
            margin: 0 auto;

            @media (min-width: 576px) {
                width: 960px;
            }

            &-bg {
                width: 100%;
                height: 100%;
            }

            &-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 68px;

                @media (min-width: 576px) {
                    width: 204px;
                }
            }

            &-text {
                position: relative;
                left: 0;
                text-align: center;
                bottom: 42px;
                color: #333;
                font-size: 8px;
                line-height: 14px;
                font-weight: bold;

                @media (min-width: 576px) {
                    bottom: 100px;
                    font-size: 20px;
                    line-height: 34px;
                }
            }
        }

        &__download {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            padding: 22px 0 40px;

            @media (min-width: 576px) {
                padding: 0;
            }
        }
    }

    &-download {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__qrcode {
            width: 70px;

            @media (min-width: 576px) {
                width: 130px;
            }
        }

        &__desc {
            margin-top: 5px;
            font-size: 9px;
            color: #333;
            text-align: center;

            @media (min-width: 576px) {
                margin-top: 29px;
                font-size: 14px;
            }

            p {
                margin: 0;
                white-space: nowrap;
            }
        }
    }

    &-app {
        width: 86px;
        margin-right: 43px;

        @media (min-width: 576px) {
            width: 290px;
            margin-right: 133px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.usage {
    display: flex;
    flex-direction: column;

    & + & {
        margin-top: 56px;

        @media (min-width: 576px) {
            margin-top: 107px;
        }
    }

    @media (min-width: 576px) {
        position: relative;
        width: 960px;
        margin: 0 auto;
        border-left: 1px solid #707070;
    }

    &__product {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;

        @media (min-width: 576px) {
            position: absolute;
            left: -220px;
            top: 0;
            width: 277px;
        }

        img {
            width: 140px;
        }

        span {
            color: #302d2c;
            font-size: 15px;
            font-weight: bold;
            text-align: center;
        }
    }

    &__list {
        padding: 0 14px;
    }

    &__item {
        & + & {
            margin-top: 14px;
        }
    }

    &__title {
        color: #333;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 6px;
        font-weight: bold;

        @media (min-width: 576px) {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 27px;
        }
    }

    &__content {
        font-size: 9px;
        line-height: 12px;
        color: #333;

        @media (min-width: 576px) {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 27px;
        }

        p {
            margin: 0;
        }
    }

    &__img {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 16px;

        @media (min-width: 576px) {
            justify-content: flex-start;
        }

        &.img1 {
            img {
                &:first-child {
                    width: 140px;
                }

                &:last-child {
                    width: 80px;
                    margin-left: 20px;
                }
            }
        }

        &.img3 {
            img {
                width: 148px;
            }
        }
    }
}

.more-support {
    padding: 10px 14px 40px;
    font-size: 12px;
    line-height: 14px;
    color: #333;

    @media (min-width: 576px) {
        width: 960px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 21px;
        padding: 0;
        padding-bottom: 120px;
    }
}

.faq {
    &__title {
        text-transform: uppercase;
        padding: 10px 15px 15px;
        font-size: 18px;
        line-height: 1;
        margin: 0;

        @media (min-width: 576px) {
            font-size: 36px;
            padding: 66px 55px;
        }
    }

    &__content {
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 34px;

        @media (min-width: 576px) {
            // margin-bottom: 160px;
            width: 960px;
        }
    }

    &-list {
        list-style: none;
        margin: 0;
        padding: 0 15px;

        @media (min-width: 576px) {
            padding: 0;
        }

        &__item {
            & + & {
                margin-top: 14px;

                @media (min-width: 576px) {
                    margin-top: 60px;
                }
            }
        }
    }

    &-item {
        color: #333;

        &__q {
            font-size: 12px;
            font-weight: bold;

            @media (min-width: 576px) {
                font-size: 18px;
            }
        }

        &__a {
            margin-top: 7px;
            font-size: 9px;
            line-height: 18px;

            @media (min-width: 576px) {
                margin-top: 24px;
                font-size: 16px;
                line-height: 40px;
            }
        }
    }
}

.about-us {
    &__banner {
        img {
            width: 100%;
            vertical-align: bottom;
        }
    }

    &__title {
        font-size: 18px;
        line-height: 1;
        color: #333;
        font-weight: bold;
        text-transform: uppercase;
        padding: 31px 9px 24px;

        @media (min-width: 576px) {
            font-size: 36px;
            padding: 65px 55px 42px 55px;
        }
    }

    &__content {
        padding: 0 9px 47px;
        font-size: 9px;
        line-height: 14px;

        @media (min-width: 576px) {
            padding: 0 55px 48px;
            font-size: 14px;
            line-height: 1.9;
        }

        p + p {
            margin-top: 33px;

            @media (min-width: 576px) {
                margin-top: 63px;
            }
        }
    }
}
