body {
  font-family: -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
a:hover {
  text-decoration: none;
}
.wrapper {
  position: relative;
}
.main {
  position: relative;
}
.header {
  height: 56px;
  z-index: 1024;
  position: relative;
}
@media (min-width: 576px) {
  .header.variant {
    position: relative;
  }
}
@media (min-width: 576px) {
  .header {
    height: 80px;
    background: #fff;
  }
}
.header__inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .header__inner {
    width: 850px;
    margin: 0 auto;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  margin-left: 26px;
}
@media (min-width: 576px) {
  .header__logo {
    margin-left: 0;
  }
}
.header__logo img {
  width: 66px;
}
@media (min-width: 576px) {
  .header__logo img {
    width: 120px;
  }
}
.header__btn {
  width: 46px;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  color: #707070;
}
.header__btn:focus {
  outline: none;
}
@media (min-width: 576px) {
  .header__btn {
    display: none;
  }
}
.header__nav {
  display: none;
}
@media (min-width: 576px) {
  .header__nav {
    display: flex;
    height: 100%;
  }
}
@media (min-width: 576px) {
  .header__nav a {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    padding: 0;
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition: all 0.3s ease;
  }
}
.header__nav a:hover,
.header__nav a:active,
.header__nav a.active {
  color: #4a4a4a;
}
@media (min-width: 576px) {
  .header__nav a.header__buy {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c00;
    width: 88px;
    height: 34px;
    line-height: 34px;
    border-radius: 17px;
    color: #fff;
    margin-left: 16px;
  }
}
@media (min-width: 576px) {
  .header__links {
    display: flex;
    align-items: center;
  }
}
.header__small-nav {
  border-top: 1px solid #999999;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  -webkit-transition: height 0.35s ease-out;
  transition: height 0.35s ease-out;
  display: none;
  overflow: hidden;
}
.header__small-nav.show {
  display: block;
}
@media (min-width: 576px) {
  .header__small-nav {
    display: none;
  }
}
.header__small-links {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}
.header__small-link {
  position: relative;
  color: #505050;
  padding: 20px 40px;
  font-size: 12px;
  line-height: 20px;
}
.header__small-link.active {
  color: #000;
}
.header__small-link.active::before {
  background: #cc0000;
}
.header__small-link::before {
  content: '';
  position: absolute;
  top: 27px;
  left: 30px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #707070;
}
.header__small-link.products {
  padding-bottom: 10px;
}
.header__products {
  padding-top: 14px;
}
.header__product {
  display: flex;
  align-items: center;
}
.header__product img {
  width: 20px;
  margin-right: 6px;
}
.header__product span {
  color: #505050;
  font-size: 10px;
}
.header__product:not(:first-child) img {
  margin-top: -10px;
}
.header__product:last-child img {
  width: 34px;
  margin-top: 0;
  margin-left: -14px;
  position: relative;
  left: 3px;
}
.second-nav {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  height: 0;
}
.second-nav.show {
  transition: height 0.35s ease-out;
}
.second-nav.show .second-nav__link {
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}
.second-nav__link {
  width: 200px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.second-nav__link > img {
  display: block;
  width: 100px;
}
.second-nav__link > span {
  margin: 12px 0 54px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  transition: all 0.3s ease;
}
.second-nav__link:hover > span {
  color: #000;
}
.second-nav__link:last-child {
  animation-delay: 0.1s;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@media (min-width: 576px) {
  .banner {
    height: 100vh;
    max-height: 1080px;
  }
}
.banner__item .lg {
  display: none;
}
@media (min-width: 576px) {
  .banner__item .lg {
    display: block;
    object-position: top;
  }
}
@media (min-width: 576px) {
  .banner__item .sm {
    display: none;
  }
}
.banner__item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border: 1px solid #707070;
  background: transparent;
  opacity: 1;
}
@media (min-width: 576px) {
  .banner .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
  }
}
.banner .swiper-pagination-bullet-active {
  background: #fff;
}
.banner > .swiper-pagination-bullets {
  bottom: 5px;
}
@media (min-width: 576px) {
  .banner > .swiper-pagination-bullets {
    bottom: 25px;
  }
}
.banner > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 2px;
}
@media (min-width: 576px) {
  .banner > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.banner__buttons-wrapper {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
.banner__buttons {
  position: absolute;
  left: 16px;
  right: 0;
  bottom: 30px;
  z-index: 10;
  display: flex;
}
@media (min-width: 576px) {
  .banner__buttons {
    left: 140px;
    bottom: 90px;
  }
}
.banner-button {
  display: flex;
  width: 66px;
  height: 22px;
}
@media (min-width: 576px) {
  .banner-button {
    width: 120px;
    height: 40px;
  }
}
.banner-button + .banner-button {
  margin-left: 10px;
}
@media (min-width: 576px) {
  .banner-button + .banner-button {
    margin-left: 30px;
  }
}
.banner-button > img {
  display: block;
  width: 100%;
}
@media (min-width: 576px) {
  .product-banner {
    height: 100vh;
    max-height: 1080px;
  }
}
.product-banner__item {
  height: 100%;
}
.product-banner__item .lg {
  display: none;
}
@media (min-width: 576px) {
  .product-banner__item .lg {
    display: block;
  }
}
@media (min-width: 576px) {
  .product-banner__item .sm {
    display: none;
  }
}
.product-banner__item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.intro {
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 18px;
}
@media (min-width: 576px) {
  .intro {
    padding: 40px 0 160px;
  }
}
@media (min-width: 576px) {
  .intro.product .intro-item {
    flex-direction: row-reverse;
  }
  .intro.product .intro-item:nth-of-type(2n) {
    flex-direction: row;
  }
}
.intro__item.a1,
.intro__item.p1,
.intro__item.x,
.intro__item.b1 {
  flex-direction: row;
  justify-content: center;
  margin-bottom: 33px;
}
@media (min-width: 576px) {
  .intro__item.a1,
  .intro__item.p1,
  .intro__item.x,
  .intro__item.b1 {
    margin-bottom: 172px;
  }
  .intro-item + .intro__item.a1,
  .intro-item + .intro__item.p1,
  .intro-item + .intro__item.x,
  .intro-item + .intro__item.b1 {
    margin-top: 146px;
  }
}
.intro__item.a1 .intro-item__img,
.intro__item.p1 .intro-item__img,
.intro__item.x .intro-item__img,
.intro__item.b1 .intro-item__img {
  flex: none;
  width: 70px;
  margin-right: 16px;
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .intro__item.a1 .intro-item__img,
  .intro__item.p1 .intro-item__img,
  .intro__item.x .intro-item__img,
  .intro__item.b1 .intro-item__img {
    width: 326px;
    margin-right: 128px;
    margin-bottom: 15px;
  }
}
.intro__item.a1 .intro-item__content,
.intro__item.p1 .intro-item__content,
.intro__item.x .intro-item__content,
.intro__item.b1 .intro-item__content {
  flex: none;
  padding: 0;
}
.intro__item.p1 .intro-item__img {
  margin-right: 6px;
  width: 82px;
}
@media (min-width: 576px) {
  .intro__item.p1 .intro-item__img {
    margin-right: 52px;
    width: 480px;
  }
}
@media (min-width: 576px) {
  .intro-item + .intro__item.x {
    flex-direction: row;
  }
}
.intro__item.x .intro-item__img {
  margin-right: 0;
  width: 110px;
  margin-right: -11px;
}
@media (min-width: 576px) {
  .intro__item.x .intro-item__img {
    margin-right: 0;
    width: 494px;
  }
}
@media (min-width: 576px) {
  .intro-item + .intro__item.b1 {
    flex-direction: row;
  }
}
.intro__item.b1 .intro-item__img {
  margin-right: 6px;
  width: 84px;
}
@media (min-width: 576px) {
  .intro__item.b1 .intro-item__img {
    width: 361px;
    margin-right: 100px;
  }
}
@media (min-width: 576px) {
  .intro__item + .intro__item {
    margin-top: 40px;
  }
  .intro__item:nth-of-type(2n) {
    flex-direction: row-reverse;
  }
}
.intro-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}
@media (min-width: 576px) {
  .intro-item {
    flex-direction: row;
  }
}
.intro-item__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 576px) {
  .intro-item__content {
    padding: 0;
  }
}
.intro-item__content--with-img {
  flex-direction: column-reverse;
}
@media (min-width: 576px) {
  .intro-item__content--with-img {
    flex-direction: column;
  }
}
.intro-item__img {
  position: relative;
  flex: 1;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 576px) {
  .intro-item__img {
    margin-bottom: 0;
  }
}
.intro-item__img img {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  object-fit: contain;
}
.intro-item__name {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #302d2c;
}
@media (min-width: 576px) {
  .intro-item__name {
    display: none;
  }
}
.intro-item__head {
  color: #302d2c;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 12px;
  padding: 0 10px;
}
@media (min-width: 576px) {
  .intro-item__head {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 32px;
    padding: 0 40px;
  }
  .X-steps .intro-item__head {
    margin-bottom: 4px;
  }
}
@media (min-width: 1920px) {
  .intro-item__head {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 40px;
    padding: 0 60px;
  }
}
.intro-item__desc {
  font-size: 9px;
  line-height: 16px;
  color: #5f5e5e;
  padding: 0 10px;
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .intro-item__desc {
    font-size: 18px;
    line-height: 1.5;
    padding: 0 40px;
  }
}
@media (min-width: 1920px) {
  .intro-item__desc {
    font-size: 20px;
    line-height: 34px;
    padding: 0 60px;
  }
}
.intro-item__certification-mark {
  display: none;
  width: 100%;
  vertical-align: bottom;
}
@media (min-width: 576px) {
  .intro-item__certification-mark {
    margin-top: 42px;
    display: block;
  }
}
.intro-item__certification-mark--mini {
  display: block;
  margin-top: 10px;
}
@media (min-width: 576px) {
  .intro-item__certification-mark--mini {
    display: none;
  }
}
.intro-item__steps {
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 576px) {
  .intro-item__steps {
    padding-top: 40px;
    padding-left: 40px;
  }
}
@media (min-width: 1920px) {
  .intro-item__steps {
    padding-top: 55px;
    padding-left: 60px;
  }
}
.intro-item__step {
  display: flex;
}
.intro-item__step + .intro-item__step {
  margin-top: 24px;
}
@media (min-width: 576px) {
  .intro-item__step + .intro-item__step {
    margin-top: 26px;
  }
}
@media (min-width: 1920px) {
  .intro-item__step + .intro-item__step {
    margin-top: 34px;
  }
}
.intro-item__step-number {
  width: 28px;
  height: 28px;
  margin-right: 8px;
  background: #c00;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 576px) {
  .intro-item__step-number {
    width: 48px;
    height: 48px;
    font-size: 28px;
    line-height: 34px;
    margin-right: 29px;
  }
}
@media (min-width: 1920px) {
  .intro-item__step-number {
    width: 56px;
    height: 56px;
    font-size: 28px;
    line-height: 34px;
    margin-right: 29px;
  }
}
.intro-item__step-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.intro-item__step-name {
  color: #1a1311;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
}
@media (min-width: 576px) {
  .intro-item__step-name {
    font-size: 22px;
  }
}
@media (min-width: 1920px) {
  .intro-item__step-name {
    font-size: 25px;
  }
}
.X-steps .intro-item__step-name {
  color: #5f5e5e;
}
.intro-item__step-desc {
  margin-top: 6px;
  font-size: 10px;
  line-height: 1;
  color: #5f5e5e;
}
@media (min-width: 576px) {
  .intro-item__step-desc {
    font-size: 18px;
    margin-top: 8px;
  }
}
@media (min-width: 1920px) {
  .intro-item__step-desc {
    font-size: 20px;
    margin-top: 13px;
  }
}
.intro-item__table-name {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 16px;
  font-weight: bold;
  color: #302d2c;
}
@media (min-width: 576px) {
  .intro-item__table-name {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 44px;
  }
}
.intro-item__table-img img {
  display: block;
  width: 100%;
}
.intro-item__table-img.lg {
  display: none;
}
@media (min-width: 576px) {
  .intro-item__table-img.lg {
    display: block;
  }
}
.intro-item__table-img.sm {
  width: 180px;
}
@media (min-width: 576px) {
  .intro-item__table-img.sm {
    display: none;
  }
}
.intro-item__table {
  margin: 0 15px 20px;
  border: 1px solid #5f5e5e;
  border-left: none;
  border-right: none;
}
@media (min-width: 576px) {
  .intro-item__table {
    margin: 0;
  }
}
.intro-item__tr {
  display: flex;
  border-bottom: 1px solid #8e8e8f;
}
.intro-item__tr:last-child {
  border-bottom: none;
}
.intro-item__td {
  padding: 0 8px;
  margin: 6px 0;
  width: 33.33333333%;
  font-size: 9px;
  line-height: 16px;
  color: #5f5e5e;
  border-right: 1px solid #8e8e8f;
}
.intro-item__td:last-child {
  border-right: none;
}
@media (min-width: 576px) {
  .intro-item__td {
    font-size: 20px;
    line-height: 38px;
  }
}
.footer {
  background: #eeeeee;
  padding: 14px 0;
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .footer {
    padding: 58px 0;
  }
}
.footer__left,
.footer__right {
  padding: 0 6px;
}
@media (min-width: 576px) {
  .footer__left,
  .footer__right {
    padding: 0 50px;
  }
}
.footer__right {
  border-left: 1px solid #979797;
}
.footer-contact__title {
  color: #4a4a4a;
  font-size: 10px;
  font-weight: bold;
  line-height: 17px;
  margin-top: 4px;
  margin-bottom: 6px;
}
@media (min-width: 576px) {
  .footer-contact__title {
    font-size: 14px;
    line-height: 24px;
    margin-top: 14px;
    margin-bottom: 9px;
  }
}
.footer-contact__content {
  font-size: 6px;
  color: #4a4a4a;
}
@media (min-width: 576px) {
  .footer-contact__content {
    font-size: 10px;
  }
}
.footer-contact__meta {
  margin-bottom: 0;
}
.footer-contact__meta + .footer-contact__meta {
  margin-top: 1px;
}
@media (min-width: 576px) {
  .footer-contact__meta + .footer-contact__meta {
    margin-top: 9px;
  }
}
.footer-contact__meta a {
  color: #4a4a4a;
}
.footer-contact__meta a:hover {
  color: #7d7d7d;
}
.footer-contact__meta a:active {
  color: #3d3d3d;
}
.footer-contact__sns {
  margin-top: 9px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .footer-contact__sns {
    margin-top: 34px;
    font-size: 27px;
  }
  .footer-contact__sns a + a {
    margin-left: 30px;
  }
}
.footer-contact__sns a {
  color: #000;
}
.footer-contact__sns a:hover {
  color: #333333;
}
.footer-contact__sns a:active {
  color: #000000;
}
.footer-contact__company {
  margin-top: 12px;
}
@media (min-width: 576px) {
  .footer-contact__company {
    margin-top: 23px;
  }
}
.support {
  max-width: 1920px;
  margin: 0 auto;
}
.support-intro {
  display: flex;
  flex-direction: column;
}
.support-intro + .support-intro {
  margin-top: 10px;
}
@media (min-width: 576px) {
  .support-intro + .support-intro {
    margin-top: 40px;
  }
}
@media (min-width: 576px) {
  .support-intro {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .support-intro.application .support-intro__content {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.support-intro.more {
  margin-top: 0;
  margin-bottom: 138px;
}
.support-intro__title {
  margin-top: 30px;
  margin-bottom: 6px;
  font-size: 15px;
  line-height: 1;
  color: #333;
  padding: 0 9px;
  font-weight: bold;
  text-transform: uppercase;
}
.support-intro__title > span {
  color: #707070;
  font-weight: normal;
  text-transform: lowercase;
}
@media (min-width: 576px) {
  .support-intro__title {
    margin-top: 80px;
    margin-bottom: 65px;
    font-size: 36px;
    padding: 0 55px;
  }
}
.support-intro__video,
.support-intro__download {
  flex: 1;
}
.support-intro__video {
  position: relative;
  margin: 0 auto;
}
@media (min-width: 576px) {
  .support-intro__video {
    width: 960px;
  }
}
.support-intro__video-bg {
  width: 100%;
  height: 100%;
}
.support-intro__video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
}
@media (min-width: 576px) {
  .support-intro__video-icon {
    width: 204px;
  }
}
.support-intro__video-text {
  position: relative;
  left: 0;
  text-align: center;
  bottom: 42px;
  color: #333;
  font-size: 8px;
  line-height: 14px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .support-intro__video-text {
    bottom: 100px;
    font-size: 20px;
    line-height: 34px;
  }
}
.support-intro__download {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 22px 0 40px;
}
@media (min-width: 576px) {
  .support-intro__download {
    padding: 0;
  }
}
.support-download {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.support-download__qrcode {
  width: 70px;
}
@media (min-width: 576px) {
  .support-download__qrcode {
    width: 130px;
  }
}
.support-download__desc {
  margin-top: 5px;
  font-size: 9px;
  color: #333;
  text-align: center;
}
@media (min-width: 576px) {
  .support-download__desc {
    margin-top: 29px;
    font-size: 14px;
  }
}
.support-download__desc p {
  margin: 0;
  white-space: nowrap;
}
.support-app {
  width: 86px;
  margin-right: 43px;
}
@media (min-width: 576px) {
  .support-app {
    width: 290px;
    margin-right: 133px;
  }
}
.support-app img {
  width: 100%;
  height: 100%;
}
.usage {
  display: flex;
  flex-direction: column;
}
.usage + .usage {
  margin-top: 56px;
}
@media (min-width: 576px) {
  .usage + .usage {
    margin-top: 107px;
  }
}
@media (min-width: 576px) {
  .usage {
    position: relative;
    width: 960px;
    margin: 0 auto;
    border-left: 1px solid #707070;
  }
}
.usage__product {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .usage__product {
    position: absolute;
    left: -220px;
    top: 0;
    width: 277px;
  }
}
.usage__product img {
  width: 140px;
}
.usage__product span {
  color: #302d2c;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.usage__list {
  padding: 0 14px;
}
.usage__item + .usage__item {
  margin-top: 14px;
}
.usage__title {
  color: #333;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 6px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .usage__title {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 27px;
  }
}
.usage__content {
  font-size: 9px;
  line-height: 12px;
  color: #333;
}
@media (min-width: 576px) {
  .usage__content {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 27px;
  }
}
.usage__content p {
  margin: 0;
}
.usage__img {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .usage__img {
    justify-content: flex-start;
  }
}
.usage__img.img1 img:first-child {
  width: 140px;
}
.usage__img.img1 img:last-child {
  width: 80px;
  margin-left: 20px;
}
.usage__img.img3 img {
  width: 148px;
}
.more-support {
  padding: 10px 14px 40px;
  font-size: 12px;
  line-height: 14px;
  color: #333;
}
@media (min-width: 576px) {
  .more-support {
    width: 960px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 21px;
    padding: 0;
    padding-bottom: 120px;
  }
}
.faq__title {
  text-transform: uppercase;
  padding: 10px 15px 15px;
  font-size: 18px;
  line-height: 1;
  margin: 0;
}
@media (min-width: 576px) {
  .faq__title {
    font-size: 36px;
    padding: 66px 55px;
  }
}
.faq__content {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 34px;
}
@media (min-width: 576px) {
  .faq__content {
    width: 960px;
  }
}
.faq-list {
  list-style: none;
  margin: 0;
  padding: 0 15px;
}
@media (min-width: 576px) {
  .faq-list {
    padding: 0;
  }
}
.faq-list__item + .faq-list__item {
  margin-top: 14px;
}
@media (min-width: 576px) {
  .faq-list__item + .faq-list__item {
    margin-top: 60px;
  }
}
.faq-item {
  color: #333;
}
.faq-item__q {
  font-size: 12px;
  font-weight: bold;
}
@media (min-width: 576px) {
  .faq-item__q {
    font-size: 18px;
  }
}
.faq-item__a {
  margin-top: 7px;
  font-size: 9px;
  line-height: 18px;
}
@media (min-width: 576px) {
  .faq-item__a {
    margin-top: 24px;
    font-size: 16px;
    line-height: 40px;
  }
}
.about-us__banner img {
  width: 100%;
  vertical-align: bottom;
}
.about-us__title {
  font-size: 18px;
  line-height: 1;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  padding: 31px 9px 24px;
}
@media (min-width: 576px) {
  .about-us__title {
    font-size: 36px;
    padding: 65px 55px 42px 55px;
  }
}
.about-us__content {
  padding: 0 9px 47px;
  font-size: 9px;
  line-height: 14px;
}
@media (min-width: 576px) {
  .about-us__content {
    padding: 0 55px 48px;
    font-size: 14px;
    line-height: 1.9;
  }
}
.about-us__content p + p {
  margin-top: 33px;
}
@media (min-width: 576px) {
  .about-us__content p + p {
    margin-top: 63px;
  }
}

/*# sourceMappingURL=GIMDOW-A1.3dbc883b.css.map */
